import React from 'react'
import Helmet from 'react-helmet'
import Header from '../components/Header'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import picametyst from '../assets/images/picametyst.jpg'
import pic02 from '../assets/images/pic02.jpg'





class Generic extends React.Component {
  
  render() {
    const title="Recenze mých klientů"

    return (
      <Layout>
        <Helmet title={title} />
        <Header />
        <div id="main">
        <span className="image main" style={{backgroundImage: `url(${picametyst})`}}></span>
          <section id="content" className="main">
          <div className="content">
                         
                <header className="major">
                  <h2>{title}</h2>
                </header>

                

                

                
                

                <div className="text bold">
                    <strong>Andrea Blahutová</strong>
                  <p>„Doporučuji. Již po první masáži super pocit. Není to, ale jen o masáži, masérka se stala mou kamarádkou vždy mě nabije pozitivní energií a radostí. Je příjemné, když po náročném dni si zajdete na luxusní masáž ze které odcházíte odpočatí, uvolnění a v dobré náladě, která dlouho přetrvává.“</p>
                </div>
                
                <div className="text bold">
                    <strong>Vojta Feruga</strong>
                  <p>„Masáže Ametyst mě příjemně překvapili. Jsem pravidelný zákazník/kamarád již hodně let a vždy se mi dostalo kvalitní masáže stejně tak skvělé konverzace. Samozřejmě za zmínku stojí i útulné prostředí a příjemná hudba.“</p>
                </div>

                <div className="text bold">
                    <strong>Andrea Sucháčková </strong>
                  <p>„Úžasná masáž od ohromně příjemné paní masérky, rozhodně doporučuji! :)“ </p>
                </div>
                       
                <div className="text bold">
                    <strong>S láskou a světlem v srdci Ivo Ševčík</strong>
                  <p>„Úžasná masáž od skvělé masérky, která působí velmi pozitivním dojmem. V krásně energeticky čistém prostředí. Rád se vracím a všem vřele doporučuji.“</p>
                </div>

                 <div className="text bold">
                    <strong>Regína Kocúrová</strong>
                  <p>„To není jen masérka, ale vodopád energie!  Jsem s masážemi maximálně spokojena, lávovky a tygrování za mě TOP!“</p>
                </div>
   
                  
   


            </div>

          </section>
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>O mně</h2>
                </header>
                <p><em>Díky své empatii dokáži vnímat zdánlivé problémy, které během masáže odbourávám. Mám za sebou dlouholetou masérskou praxi a ve svém volném čase se účastním dalších seminářů zaměřených na regeneraci těla i duše. </em></p>
                
                <ul className="actions">

                  <li><Link to="/" className="button">Zpět na Masáže Ametyst</Link> </li>
                </ul>

              </div>
              <span className="image"><img src={pic02} alt="" /></span>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
